import {Text} from "../Text";
import Modal from 'react-bootstrap/Modal';
import {Button} from "../Button";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../services/i18n/i18nSlice";
import { useEffect, useState } from "react";
import { selectAppInfo, selectAppPersonalizations } from "../../services/utils/appInfoSlice";

import mail_img from '../../assets/images/mail.png'
import img_close from '../../assets/images/close.svg'
import { TitleH1 } from "../TitleH1";
import { GetFromLocalStorage, SaveToLocalStorage } from "../../utils/utils";
import { API_URL } from "../../config/constants";
import { isMobile } from "react-device-detect";
import { selectUserData } from "../../features/User/userSlice";

export default function MailModal () {

    
    const t = useSelector(selectTranslations)

    const appPersonalizations = useSelector(selectAppPersonalizations)
    const appInfo = useSelector(selectAppInfo)
    const userInfo = useSelector(selectUserData)
    
    const [show, setShow] = useState(false)

    
    useEffect(()=>{
        if(appInfo?.is_ofa && userInfo?.email?.includes("privaterelay.appleid.com"))
            setShow(true)
    },[userInfo?.email])

    const closeModal = () => {
        setShow(false)
    }


    return (
        <Modal
            show={show && appPersonalizations?.logo_url}
            keyboard={false}
            centered
            size="md"
        >
            <Modal.Body className="text-center" style={{minHeight:'40vh'}}>
                <div className="d-flex justify-content-end mb-2">
                    <img src={img_close} alt="" className="pointer" onClick={closeModal} />
                </div>
                <img src={mail_img} style={{maxWidth:'50%', maxHeight:'60px'}} alt="" />
                <TitleH1 className="mt-3 px-2">Hai un'altra mail?</TitleH1>
                <Text className="mt-2 px-2">Esci con il pulsante in alto a sinistra e <strong>inserisci su thefaculty</strong> una "mail di contatto" (sul tuo Profilo) che <strong>non sia una mail Apple</strong>, altrimenti potresti non ricevere certificati o non riuscire ad accedere da pc.</Text>
                <div className=" mt-4">
                    <Button color="secondary" className="w-100 mb-3 mx-auto" onClick={closeModal}>
                        Continua comunque
                    </Button>
                    {/* <a href="https://thefacultyapp.com/?APP#PROFILE#EDIT_PROFILE">
                        <Button className="w-100 mx-auto" >
                            Vai al profilo
                        </Button>
                    </a> */}
                    
                </div>
            </Modal.Body>
        </Modal>
    )
}